import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  deleteInstallmentApi,
  downloadCustomerRepaymentsReportApi,
  getAmortizationApi,
  getCustomerInstallmentsOverviewApi,
} from 'api/dealApi';
import DataTable from 'components/DataTable';
import { Currency } from 'components/Number';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Card from 'components/card/Card';
import BackLink from 'components/links/BackLink';
import {
  INSTALLMENT_TYPE,
  INSTALLMENT_TYPE_META,
  REVENUE_RECOGNITION_TYPE,
} from 'constants/dealConstants';
import { CockpitCustomerContext } from 'context/CockpitCustomerContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { FaSync } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import AmortizationTable from './AmortizationTable';
import InstallmentStatusTag from './InstallmentStatusTag';
import OperationsKpis from './OperationsKpis';
import RevenueRecognitionSelect from './RevenueRecognitionSelect';

const columnHelper = createColumnHelper();

const getCurrencyCellValue = (info) => {
  const value = info.getValue();
  return value === null ? (
    'N/A'
  ) : (
    <Currency value={value} maximumFractionDigits={2} />
  );
};

const createColumns = (deleteInstallment) => {
  const columns = [
    columnHelper.accessor('transferDate', {
      cell: (info) =>
        info.getValue() === null ? (
          'N/A'
        ) : (
          <Text whiteSpace={'nowrap'}>
            {moment(info.getValue()).format('MMM DD, YYYY')}
          </Text>
        ),
      header: 'Transfer Date',
    }),
    columnHelper.accessor('installmentType', {
      cell: (info) =>
        info.getValue() === null ? (
          'N/A'
        ) : (
          <Text whiteSpace={'nowrap'}>
            {INSTALLMENT_TYPE_META[info.getValue()].label}
          </Text>
        ),
      header: 'Installment Type',
    }),
    columnHelper.display({
      id: 'cohortDates',
      cell: (info) => {
        const { cohortStartDate, cohortEndDate } = info.row.original;
        if (!cohortStartDate || !cohortEndDate) {
          return 'N/A';
        }

        return (
          <Text whiteSpace={'nowrap'}>
            {moment(cohortStartDate).format('MM/DD/YYYY')} -{' '}
            {moment(cohortEndDate).format('MM/DD/YYYY')}
          </Text>
        );
      },
      header: 'Cohort Dates',
    }),
    columnHelper.accessor('status', {
      cell: (info) => {
        const status = info.getValue();
        if (!status) {
          return 'N/A';
        }

        return <InstallmentStatusTag status={status} />;
      },
      header: 'Status',
    }),
    columnHelper.accessor('purchasePrice', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Purchase Price',
    }),
    columnHelper.accessor('totalSold', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Amount Sold',
    }),
    columnHelper.accessor('amountDueOutstanding', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Amount Due Outstanding',
    }),
    columnHelper.accessor('principalDueOutstanding', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Principal Due Outstanding',
    }),
    columnHelper.accessor('feeDueOutstanding', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Fee Due Outstanding',
    }),
    columnHelper.accessor('principalRepaid', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Principal Repaid',
    }),
    columnHelper.accessor('feeRepaid', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Fee Repaid',
    }),
    columnHelper.accessor('lastRepaymentAtMillis', {
      cell: (info) =>
        info.getValue() === null ? (
          'N/A'
        ) : (
          <Text whiteSpace={'nowrap'}>
            {moment(info.getValue()).format('MMM DD, YYYY')}
          </Text>
        ),
      header: 'Last Repayment Date',
    }),
    columnHelper.accessor('createdAtMillis', {
      cell: (info) =>
        info.getValue() === null ? (
          'N/A'
        ) : (
          <Text whiteSpace={'nowrap'}>
            {moment(info.getValue()).format('MMM DD, YYYY')}
          </Text>
        ),
      header: 'Date',
    }),
    columnHelper.display({
      id: 'actions',
      meta: {
        maxWidth: 10,
        align: 'right',
      },
      cell: (info) => {
        const { id, installmentType } = info.row.original;

        if (installmentType === INSTALLMENT_TYPE.PREVIEW) {
          return (
            <IconButton
              aria-label="delete"
              icon={<DeleteIcon color={'red'} />}
              pr={5}
              _hover={{ bg: 'transparent' }}
              bg={'transparent'}
              onClick={(event) => {
                deleteInstallment(id);
                event.stopPropagation();
              }}
            />
          );
        }
      },
    }),
  ];

  return columns;
};

const defaultSorting = [{ id: 'createdAtMillis', desc: true }];

const OperationsCustomerOverview = () => {
  const [loading, setLoading] = useState(true);
  const [loadingAmortization, setLoadingAmortization] = useState(true);
  const [overviewData, setOverviewData] = useState(null);
  const [amortizationData, setAmortizationData] = useState(null);
  const [revenueRecognition, setRevenueRecognition] = useState(
    REVENUE_RECOGNITION_TYPE.INTEREST_FIRST
  );
  const [isAmortizationPredicted, setIsAmortizationPredicted] = useState(true);
  const [
    showAmortizationPreviewInstallments,
    setShowAmortizationPreviewInstallments,
  ] = useState(true);

  const [overviewColumns, setOverviewColumns] = useState([]);

  const toast = useToast();
  const navigate = useNavigate();

  const { customer, isCustomerLoading } = useContext(CockpitCustomerContext);

  const customerId = customer?.id;

  useEffect(() => {
    if (customer) {
      getAmortizationData();
      setOverviewColumns(createColumns(deleteInstallment));
    }
  }, []);

  useEffect(() => {
    if (customer) {
      loadCustomerInstallmentsOverviewData();
    }
  }, [revenueRecognition]);

  const loadCustomerInstallmentsOverviewData = async () => {
    setLoading(true);
    try {
      const _data = await getCustomerInstallmentsOverviewApi({
        customerId,
        revenueRecognition,
      });
      setOverviewData(_data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch installments data',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getAmortizationData = async ({ refreshData = false } = {}) => {
    try {
      setLoadingAmortization(true);
      const _data = await getAmortizationApi({ customerId, refreshData });
      setAmortizationData(_data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch amortization data',
        status: 'error',
      });
      throw error;
    } finally {
      setLoadingAmortization(false);
    }
  };

  const deleteInstallment = async (installmentId) => {
    try {
      setLoading(true);
      await deleteInstallmentApi({ customerId, installmentId });
      await loadCustomerInstallmentsOverviewData();
      await getAmortizationData();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete installment',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  const downloadRepaymentsReport = async () => {
    try {
      await downloadCustomerRepaymentsReportApi({
        customerId,
        fileName: `repayments_report_${customer.companyName}_${moment().format(
          'MM_DD_YYYY'
        )}.csv`,
        downloadDate: moment().format('YYYY-MM-DD'),
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to download repayments report',
        status: 'error',
      });
      throw error;
    }
  };

  return (
    <Box maxW={'container.xl'} mt={-10}>
      <OverlaySpinnerWrapper show={loading || isCustomerLoading}>
        <BackLink text={'Back to customer list'} path={'/operations'} />
        <Box mt={100}>
          <Flex justifyContent={'space-between'} align={'start'}>
            <OperationsKpis {...overviewData.kpis} />
            <Flex alignItems={'center'}>
              <RevenueRecognitionSelect
                value={revenueRecognition}
                onChange={setRevenueRecognition}
              />
            </Flex>
          </Flex>

          <Flex justify={'end'}>
            <Button
              variant={'outline'}
              leftIcon={<DownloadIcon />}
              onClick={downloadRepaymentsReport}
            >
              Download Repayments Report
            </Button>
          </Flex>
          <Card
            mt={'40px'}
            w={loadingAmortization ? 'full' : 'max'}
            maxW={'full'}
            minH={loadingAmortization ? 400 : 'auto'}
            display={
              !loadingAmortization &&
              amortizationData?.installments?.length === 0
                ? 'none'
                : 'block'
            }
          >
            {loadingAmortization && (
              <AbsoluteCenter>
                {' '}
                <Spinner />
              </AbsoluteCenter>
            )}
            {amortizationData?.installments?.length > 0 && (
              <>
                <Flex justify={'space-between'} align={'center'}>
                  <Flex gap={2} align={'baseline'}>
                    <Heading size="md">Amortization Table</Heading>
                    {amortizationData?.lastUpdateMillis && (
                      <Text>
                        (Last Revenue Update:{' '}
                        {moment(amortizationData?.lastUpdateMillis).format(
                          'MMM DD, YYYY HH:mm'
                        )}
                        )
                      </Text>
                    )}
                  </Flex>
                  <Flex gap={5} align={'center'}>
                    <Flex gap={1} align={'center'}>
                      <FormLabel m={0} htmlFor="isChecked">
                        Show Preview Installments
                      </FormLabel>
                      <Switch
                        id="showAmortizationPreviewInstallments"
                        isChecked={showAmortizationPreviewInstallments}
                        onChange={(event) =>
                          setShowAmortizationPreviewInstallments(
                            event.target.checked
                          )
                        }
                        size={'xl'}
                      />
                    </Flex>
                    <Flex gap={1} align={'center'}>
                      <FormLabel m={0} htmlFor="isChecked">
                        Is Predicted
                      </FormLabel>
                      <Switch
                        id="isPredicted"
                        isChecked={isAmortizationPredicted}
                        onChange={(event) =>
                          setIsAmortizationPredicted(event.target.checked)
                        }
                        size={'xl'}
                      />
                    </Flex>
                    <Button
                      variant={'outline'}
                      leftIcon={<Icon as={FaSync} />}
                      onClick={() => getAmortizationData({ refreshData: true })}
                    >
                      Refresh Revenue Data
                    </Button>
                  </Flex>
                </Flex>
                <Box mt={5} mx={-5}>
                  {!loadingAmortization && (
                    <AmortizationTable
                      data={amortizationData.installments}
                      isPredicted={isAmortizationPredicted}
                      showPreviewInstallments={
                        showAmortizationPreviewInstallments
                      }
                    />
                  )}
                </Box>
              </>
            )}
          </Card>
          <Card mt={'40px'}>
            <Flex justify={'end'}>
              <Flex>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    w={220}
                    variant={'outline'}
                    onClick={() => {
                      navigate(`/operations/${customerId}/installment/new`);
                    }}
                  >
                    CREATE INSTALLMENT
                  </Button>
                  <Button
                    w={220}
                    variant={'brand'}
                    onClick={() => {
                      navigate(`/operations/${customerId}/repayment`);
                    }}
                  >
                    REPAYMENT
                  </Button>
                </Stack>
              </Flex>
            </Flex>
            <Box minH={300} mx={-5} mt={10}>
              {overviewData?.installments?.length > 0 && (
                <DataTable
                  columns={overviewColumns}
                  data={overviewData.installments}
                  hasPagination={true}
                  defaultSorting={defaultSorting}
                  onRowClick={(row) => {
                    navigate(`/operations/${customerId}/installment/${row.id}`);
                  }}
                  rowHeight={'100px'}
                />
              )}
            </Box>
          </Card>
        </Box>
      </OverlaySpinnerWrapper>
    </Box>
  );
};

OperationsCustomerOverview.propTypes = {};

export default OperationsCustomerOverview;
