import {
  getCashflowModelScenarioApi,
  updateCashflowModelScenarioApi,
} from 'api/cashflowModelApi';
import CustomerSelect from 'components/cockpit/CustomerSelect';
import FileInput from 'components/inputs/FileInput';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from 'components/NumberInput';
import { renderInputElement } from 'helpers/formHelper';
import { hasObjectAllValues, isObjectEmpty } from 'helpers/objectHelper';
import cloneDeep from 'lodash.clonedeep';
import Papa from 'papaparse';
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { numberFormat, percentageFormat } from 'components/Number';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import { HelperTooltip } from 'components/Tooltip';
import { generateSelectOptions } from 'helpers/inputHelper';
import PropTypes from 'prop-types';
import CashflowModelDrivers from './CashflowModelDrivers';
import { parseAccountingCsv, parseCohortsCsv } from './csvParsers';
import ScenarioSelector from './ScenarioSelector';

const SECTION_KEY = {
  INPUT: 'input',
  MARKETING: 'marketing',
  TWELVE_FUNDING: 'twelveFunding',
};

const SECTION_CONFIG = [
  {
    title: 'Input',
    key: SECTION_KEY.INPUT,
  },
  {
    title: 'Marketing',
    key: SECTION_KEY.MARKETING,
  },
  {
    title: 'Twelve Funding',
    key: SECTION_KEY.TWELVE_FUNDING,
  },
];

const SCENARIO_TYPE = {
  BULL: 'bull',
  BEAR: 'bear',
  OTHER: 'other',
};

const SCENARIO_TYPE_META = {
  [SCENARIO_TYPE.BULL]: {
    label: 'Bull',
  },
  [SCENARIO_TYPE.BEAR]: {
    label: 'Bear',
  },
  [SCENARIO_TYPE.OTHER]: {
    label: 'Other',
  },
};

const SCENARIO_TYPE_OPTIONS = generateSelectOptions({
  metaObj: SCENARIO_TYPE_META,
});

const SectionFields = ({ title, fields, sectionKey, control, errors }) => {
  return (
    <VStack spacing={2} align={'start'}>
      <Text fontWeight={800}>{title}</Text>
      <Flex gap={10} fontSize={'sm'}>
        {fields
          .filter((field) => field.section === sectionKey)
          .map((field, index) => {
            return (
              <Fragment key={index}>
                <Box>
                  <Flex align={'center'}>
                    <Text>{field.label}</Text>
                    {field.tooltip && <HelperTooltip content={field.tooltip} />}
                  </Flex>

                  <Box mt={1}>
                    {field.isCustomField ? (
                      field.element
                    ) : (
                      <Box w={field.width || 200}>
                        <FormControl isInvalid={errors[field.name]}>
                          <Controller
                            key={index}
                            control={control}
                            name={field.name}
                            render={renderInputElement({
                              item: field,
                              showPrefix: true,
                            })}
                            rules={
                              field.validate && {
                                validate: field.validate,
                              }
                            }
                          />
                          <FormErrorMessage>
                            {errors[field]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Fragment>
            );
          })}
      </Flex>
    </VStack>
  );
};

const CashflowModelInputs = forwardRef(
  ({ onSubmit, modelResults, roasVector, onCustomerChange }, ref) => {
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [parseCohortsError, setParseCohortsError] = useState(null);
    const [parseAccountingError, setParseAccountingError] = useState(null);
    const [cohortFile, setCohortFile] = useState(null);
    const [accountingFile, setAccountingFile] = useState(null);
    const [drivers, setDrivers] = useState(null);
    const [scenarioType, setScenarioType] = useState(null);
    const [scenario, setScenario] = useState(null);

    const refDrivers = useRef(null);

    const {
      register,
      handleSubmit,
      reset,
      control,
      watch,
      setValue,
      formState: { errors },
    } = useForm();

    const formValues = watch();

    const fields = [
      {
        label: 'Customer',
        section: SECTION_KEY.INPUT,
        name: 'customerId',
        isCustomField: true,
        required:
          (formValues.roasCohorts && !formValues.accounting) ||
          (formValues.accounting && !formValues.roasCohorts),
        element: (
          <>
            <Box w={200} whiteSpace={'nowrap'}>
              <CustomerSelect
                customerId={formValues.customerId}
                onChange={(customerId) => {
                  setValue('customerId', customerId);
                }}
              />
            </Box>
          </>
        ),
      },
      {
        label: 'ROAS Cohorts CSV',
        name: 'roasCohorts',
        section: SECTION_KEY.INPUT,
        isCustomField: true,
        required: !formValues.customerId,
        element: (
          <Flex gap={1} direction={'column'}>
            <FileInput
              initialFile={cohortFile}
              allowedFileTypes={['text/csv']}
              onSelectFiles={(files) => {
                setCohortFile(files[0]);
                onSelectCohortsCsv(files[0]);
              }}
            />
            <Link
              href="csv/cashflow-model-cohorts-example.csv"
              fontSize={'xs'}
              variant={'underline'}
            >
              Example File
            </Link>
            {parseCohortsError && (
              <Text color={'red.500'}>{parseCohortsError}</Text>
            )}
          </Flex>
        ),
      },
      {
        label: 'Accounting',
        name: 'accounting',
        section: SECTION_KEY.INPUT,
        isCustomField: true,
        element: (
          <Flex gap={1} direction={'column'}>
            <FileInput
              initialFile={accountingFile}
              allowedFileTypes={['text/csv']}
              onSelectFiles={(files) => {
                setAccountingFile(files[0]);
                onSelectAccountingCsv(files[0]);
              }}
            />
            <Link
              href="csv/cashflow-model-accounting-example.csv"
              fontSize={'xs'}
              variant={'underline'}
            >
              Example File
            </Link>
            {parseAccountingError && (
              <Text color={'red.500'}>{parseAccountingError}</Text>
            )}
          </Flex>
        ),
      },
      {
        label: 'Cash Override',
        section: SECTION_KEY.INPUT,
        name: 'cashOverride',
        element: CurrencyNumberInput,
        formatCsv: numberFormat,
        isInCsv: true,
        tooltip:
          'This will override the cash value for the last month of actual data and will be used as the starting point for cash prediction calculations.',
      },
      {
        label: 'Direct Marketing Spend',
        section: SECTION_KEY.MARKETING,
        name: 'uaSpend',
        element: CurrencyNumberInput,
        formatCsv: numberFormat,
        required: true,
      },
      {
        label: 'Number Of Iterations',
        section: SECTION_KEY.MARKETING,
        name: 'uaSpendNumberOfIterations',
        element: TwNumberInput,
        formatCsv: numberFormat,
        required: true,
        validate: {
          largerThanNumberOfFunding: (value) => {
            return (
              value >= formValues.numberOfFunding ||
              'Should be equal or bigger than Number Of Funding'
            );
          },
        },
      },
      {
        label: 'Factor',
        section: SECTION_KEY.MARKETING,
        name: 'uaSpendFactor',
        element: TwNumberInput,
        props: { min: 0 },
        formatCsv: numberFormat,
        required: true,
        validate: {
          notZero: (value) => {
            return value !== 0 || `Factor can't be zero`;
          },
        },
      },
      {
        label: 'Funding from UA',
        section: SECTION_KEY.TWELVE_FUNDING,
        name: 'twelveFundingFromUa',
        element: PercentageNumberInput,
        formatCsv: percentageFormat,
        required: true,
        width: 125,
      },
      {
        label: 'Number Of Funding',
        section: SECTION_KEY.TWELVE_FUNDING,
        name: 'numberOfFunding',
        element: TwNumberInput,
        formatCsv: numberFormat,
        required: true,
        width: 125,
      },
      {
        label: 'Number Of Repayments',
        section: SECTION_KEY.TWELVE_FUNDING,
        name: 'numberOfRepayments',
        element: TwNumberInput,
        formatCsv: numberFormat,
        required: true,
        width: 125,
      },
      {
        label: 'Fee',
        section: SECTION_KEY.TWELVE_FUNDING,
        name: 'fee',
        formatCsv: percentageFormat,
        element: PercentageNumberInput,
        required: true,
        width: 125,
      },
      {
        label: 'Grace',
        section: SECTION_KEY.TWELVE_FUNDING,
        name: 'grace',
        element: TwNumberInput,
        formatCsv: numberFormat,
        required: true,
        width: 125,
        tooltip:
          'If the Grace period is set to 0, the first repayment will occur in the same month as the funding date.',
      },
      {
        label: 'Revenue Share',
        section: SECTION_KEY.TWELVE_FUNDING,
        name: 'revenueShare',
        formatCsv: percentageFormat,
        element: PercentageNumberInput,
        width: 125,
      },
    ];

    useEffect(() => {
      const values = fields.reduce((acc, field) => {
        if (field.name) {
          acc[field.name] = null;
        }
        return acc;
      }, {});
      reset(values);
    }, []);

    useEffect(() => {
      if (formValues.customerId) {
        loadScenario({
          customerId: formValues.customerId,
          scenarioType: SCENARIO_TYPE.BULL,
        });
      }
      onCustomerChange();
    }, [formValues.customerId]);

    useImperativeHandle(
      ref,
      () => {
        return {
          generateCsvData: () => {
            let csvData = fields.reduce((result, field) => {
              if (field.formatCsv) {
                result.push([
                  field.label,
                  field.formatCsv(formValues[field.name]),
                ]);
              }
              return result;
            }, []);

            const driversCsv = refDrivers.current.generateCsvData();
            return [...csvData, ...driversCsv];
          },
        };
      },
      [formValues]
    );

    const loadScenario = async ({ customerId, scenarioType }) => {
      try {
        setLoading(true);
        const _scenario = await getCashflowModelScenarioApi({
          customerId,
          scenarioType,
        });
        let settings = _scenario?.settings;

        if (!settings) {
          settings = fields.reduce((acc, field) => {
            if (field.name && field.name !== 'customerId') {
              acc[field.name] = null;
            }
            return acc;
          }, {});
        }

        const newValues = { customerId, ...settings };
        reset(newValues);

        setScenario(_scenario);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to get default settings.',
          status: 'error',
        });
        throw error;
      } finally {
        setLoading(false);
      }
    };

    const updateScenario = async () => {
      if (!formValues.customerId) {
        return;
      }

      const customerId = formValues.customerId;
      const settings = {
        uaSpend: formValues.uaSpend,
        uaSpendNumberOfIterations: formValues.uaSpendNumberOfIterations,
        uaSpendFactor: formValues.uaSpendFactor,
        twelveFundingFromUa: formValues.twelveFundingFromUa,
        numberOfFunding: formValues.numberOfFunding,
        numberOfRepayments: formValues.numberOfRepayments,
        fee: formValues.fee,
        grace: formValues.grace,
        revenueShare: formValues.revenueShare,
      };

      try {
        setLoading(true);
        await updateCashflowModelScenarioApi({
          customerId,
          scenarioType,
          settings,
          drivers,
          comments: scenario.comments,
        });
        toast({
          title: 'Success',
          description: 'Default settings updated.',
          status: 'success',
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to update default settings.',
          status: 'error',
        });
        throw error;
      } finally {
        setLoading(false);
      }
    };

    const onSelectCohortsCsv = (file) => {
      const onParseComplete = async (csvData) => {
        let result = [];

        try {
          result = await parseCohortsCsv(csvData);
          setValue('roasCohorts', result);
        } catch (error) {
          setParseCohortsError(error);
        }
      };

      setParseCohortsError(null);
      setValue('roasCohorts', null);
      Papa.parse(file, { complete: onParseComplete });
    };

    const onSelectAccountingCsv = (file) => {
      const onParseComplete = async (csvData) => {
        let result = [];
        try {
          result = await parseAccountingCsv(csvData);
          setValue('accounting', result);
        } catch (error) {
          setParseAccountingError(error);
        }
      };

      setParseAccountingError(null);
      setValue('accounting', null);
      Papa.parse(file, { complete: onParseComplete });
    };

    const isFormFilled = () => {
      const values = cloneDeep(formValues);

      const notRequiredFields = fields
        .filter((field) => !field.required)
        .map((field) => field.name);

      notRequiredFields.forEach((field) => {
        delete values[field];
      });

      return !isObjectEmpty(formValues) && hasObjectAllValues(values);
    };

    const onFormSubmit = (data) => {
      onSubmit({ ...data, ...{ drivers } });
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <OverlaySpinnerWrapper show={loading} isFixed={true}>
          <VStack w={'max'} spacing={6} align={'start'}>
            {SECTION_CONFIG.map((section, index) => {
              return (
                <SectionFields
                  key={index}
                  title={section.title}
                  fields={fields}
                  sectionKey={section.key}
                  control={control}
                  errors={errors}
                />
              );
            })}
          </VStack>
          <Box mt={10}>
            <CashflowModelDrivers
              ref={refDrivers}
              modelResults={modelResults}
              onDriversChange={setDrivers}
              roasVector={roasVector}
              initialFactors={scenarioType && scenario?.drivers}
            />
          </Box>
          <Flex mt={10} align={'center'}>
            <FormControl>
              <Checkbox
                id={'includePreviewInstallments'}
                {...register('includePreviewInstallments')}
              >
                Include Preview Installments
              </Checkbox>
            </FormControl>
            <Flex justify={'end'} gap={4}>
              {formValues.customerId && modelResults && (
                <ScenarioSelector
                  value={scenarioType || ''}
                  options={SCENARIO_TYPE_OPTIONS}
                  onChange={(value) => {
                    setScenarioType(value);
                    loadScenario({
                      customerId: formValues.customerId,
                      scenarioType: value,
                    });
                  }}
                  comments={scenarioType && scenario?.comments}
                  onCommentsChange={(value) => {
                    setScenario((prevScenario) => ({
                      ...prevScenario,
                      comments: value,
                    }));
                  }}
                  onSave={updateScenario}
                  isSaveDisabled={!isFormFilled() || !scenarioType}
                />
              )}
              <Button
                variant={'brand'}
                type={'submit'}
                isDisabled={!isFormFilled()}
              >
                Calculate
              </Button>
            </Flex>
          </Flex>
        </OverlaySpinnerWrapper>
      </form>
    );
  }
);

CashflowModelInputs.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  modelResults: PropTypes.object,
  roasVector: PropTypes.array,
  onCustomerChange: PropTypes.func,
};

export default CashflowModelInputs;
